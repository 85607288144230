<template lang="pug">
  .car-class-settings
    AppNestedRoutesTabs(
      :routes="routes"
      :default-prevented="isDirty"
    )
</template>

<script>
  // misc
  import { some } from "lodash-es"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import aboutModule from "@/config/store/company_system/basic_settings/about"
  import cancellationFeesModule from "@/config/store/company_system/basic_settings/cancellation_fees"
  import dropOffFeesModule from "@/config/store/company_system/basic_settings/drop_off_fees"
  import optionsModule from "@/config/store/company_system/basic_settings/options"
  import timeSlotsModule from "@/config/store/company_system/basic_settings/time_slots"

  const companyAboutMixin = withStoreModule(aboutModule, {
    name: "companyAbout",
    readers: { isAboutDirty: "isDirty" }
  })

  const cancellationFeesMixin = withStoreModule(cancellationFeesModule, {
    name: "cancellationFees",
    readers: { isCancellationFeeDirty: "isDirty" }
  })

  const dropOffFeesMixin = withStoreModule(dropOffFeesModule, {
    name: "dropOffFees",
    readers: { isDropOffFeeDirty: "isDirty" }
  })

  const optionsMixin = withStoreModule(optionsModule, {
    name: "optionsMixin",
    readers: { isOptionDirty: "isDirty" }
  })

  const timeSlotsMixin = withStoreModule(timeSlotsModule, {
    name: "timeSlots",
    readers: { isTimeSlotDirty: "isDirty" }
  })

  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    mixins: [companyAboutMixin, cancellationFeesMixin, dropOffFeesMixin, optionsMixin, timeSlotsMixin],

    computed: {
      isDirty({ isAboutDirty, isCancellationFeeDirty, isDropOffFeeDirty, isOptionDirty, isTimeSlotDirty }) {
        return some([isAboutDirty, isCancellationFeeDirty, isDropOffFeeDirty, isOptionDirty, isTimeSlotDirty])
      },

      routes() {
        return [
          {
            name: "TimeDivision",
            title: this.$t("header.title.time_division")
          },
          {
            name: "Options",
            title: this.$t("header.title.options")
          },
          {
            name: "DropOffFees",
            title: this.$t("header.title.drop_off_fees")
          },
          {
            name: "CancellationFees",
            title: this.$t("header.title.cancellation_fees")
          },
          {
            name: "About",
            title: this.$t("header.title.about")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
